<template>
  <v-container>
    <v-form ref="form" v-model="valid" class="mt-5">
      <k-field-group language-prefix="salesOrder.form.fields">
        <v-col cols="12" lg="6" md="8" offset-lg="3" offset-md="2" offset-sm="1" sm="10">
          <v-subheader class="pa-0">{{ $t(`salesOrder.form.title${isUpdateForm ? "Update" : "Add"}`) }}</v-subheader>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <KTextField field="orderNumber" :value="values.orderNumber" disabled/>
                  <KTextField field="clientName" :value="values.clientName" disabled/>
                  <KTextField field="orderDate" :value="formattedOrderDate" disabled/>
                  <KTextField field="deliveryDate" :value="formattedDeliveryDate ? formattedDeliveryDate : ''" disabled/>
                  <SalesOrderStatusAutocomplete field="status" v-model="values.status"/>
                  <IssueTypeAutocomplete field="salesOrderIssue" v-model="values.salesOrderIssue"/>
                  <UserAutocomplete field="assignedUser" v-model="values.assignedUser"/>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-subheader class="mt-6 pa-0">{{ $t("salesOrder.form.comments") }}</v-subheader>
          <v-card>
            <v-card-text>
              <KTextarea field="comment" v-model="values.comment" hide-details/>
            </v-card-text>
          </v-card>
          <v-card v-for="(comment, key) in values.comments" :key="key" class="mt-6">
            <v-card-text>
              <span class="font-weight-bold">{{ comment.name}}</span> ({{ formatDate(comment.createdAt)}}):<br />
              {{ comment.comment }}
            </v-card-text>
          </v-card>
        </v-col>
      </k-field-group>
    </v-form>
  </v-container>
</template>

<script lang="js">
import KFieldGroup from "@/components/crud/fields/KFieldGroup.vue";
import Form from "@/components/vuetifyResource/VuetifyResourceBaseForm.vue";
import KTextField from "@/components/crud/fields/KTextField.vue";
import dayjs from "@/plugins/dayjs.js";
import IssueTypeAutocomplete from "@/components/autocompletes/IssueTypeAutocomplete.vue";
import UserAutocomplete from "@/components/autocompletes/UserAutocomplete.vue";
import SalesOrderStatusAutocomplete from "@/components/autocompletes/SalesOrderStatusAutocomplete.vue";
import KTextarea from "@/components/crud/fields/KTextarea.vue";

export default {
  name: "SalesOrderForm",
  extends: Form,
  components: {
    KTextarea,
    UserAutocomplete,
    SalesOrderStatusAutocomplete,
    IssueTypeAutocomplete,
    KTextField,
    KFieldGroup,
  },
  data: () => ({
    values: {
      assignedUser: null,
      canCloseSalesOrder: false,
      clientName: "",
      deliveryDate: null,
      hasMetaData: false,
      id: null,
      orderDate: "",
      orderNumber: "",
      salesOrderIssue: null,
      status: null,
      comments: [],
      comment: ""
    },
  }),
  computed: {
    formattedOrderDate() {
      return this.formatDate(this.values.orderDate);
    },
    formattedDeliveryDate() {
      return this.formatDate(this.values.deliveryDate);
    }
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("LLL");
    },
  }
};
</script>
