<template>
  <KPaginatedAutocomplete :field="field" :paginator="autocomplete" v-bind="$attrs" v-on="$listeners"/>
</template>

<script>
import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete';
import { typeAutoComplete } from "@/api/endpoints/salesOrder";

export default {
  name: 'IssueTypeAutocomplete',
  components: { KPaginatedAutocomplete },
  props: {
    field: {
      type: String,
      required: true,
    },
  },
  methods: {
    autocomplete(...args) {
      return typeAutoComplete(...args);
    },
  },
};
</script>
